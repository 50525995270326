var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "app-container" },
    [
      _c(
        "div",
        { staticClass: "filter-container" },
        [
          _c(
            "el-button",
            {
              directives: [{ name: "waves", rawName: "v-waves" }],
              staticClass: "filter-item",
              attrs: { type: "primary", icon: "el-icon-add" },
              on: {
                click: function($event) {
                  $event.preventDefault()
                  return _vm.editItem()
                }
              }
            },
            [_vm._v("\n      " + _vm._s(_vm.$t("gameSetting.add")) + "\n    ")]
          ),
          _c("el-date-picker", {
            staticClass: "filter-item",
            staticStyle: { width: "300px" },
            attrs: {
              type: "daterange",
              align: "right",
              "unlink-panels": "",
              "range-separator": _vm.$t("statManager.to"),
              "start-placeholder": _vm.$t("statManager.startTime"),
              "end-placeholder": _vm.$t("statManager.endTime"),
              "picker-options": _vm.pickerOptions
            },
            model: {
              value: _vm.rangeTime,
              callback: function($$v) {
                _vm.rangeTime = $$v
              },
              expression: "rangeTime"
            }
          }),
          _c("el-input", {
            staticClass: "filter-item",
            staticStyle: { width: "200px" },
            attrs: { placeholder: _vm.$t("user.inputPlayerId") },
            nativeOn: {
              keyup: function($event) {
                if (
                  !$event.type.indexOf("key") &&
                  _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
                ) {
                  return null
                }
                return _vm.handleFilter($event)
              }
            },
            model: {
              value: _vm.listQuery.uid,
              callback: function($$v) {
                _vm.$set(_vm.listQuery, "uid", $$v)
              },
              expression: "listQuery.uid"
            }
          }),
          _c(
            "el-button",
            {
              directives: [{ name: "waves", rawName: "v-waves" }],
              staticClass: "filter-item",
              attrs: { type: "primary", icon: "el-icon-search" },
              on: { click: _vm.handleFilter }
            },
            [
              _vm._v(
                "\n      " + _vm._s(_vm.$t("statManager.search")) + "\n    "
              )
            ]
          )
        ],
        1
      ),
      _c(
        "el-table",
        {
          directives: [
            {
              name: "loading",
              rawName: "v-loading",
              value: _vm.listLoading,
              expression: "listLoading"
            }
          ],
          staticStyle: { width: "100%" },
          attrs: { data: _vm.list, border: "" }
        },
        [
          _c("el-table-column", {
            attrs: { prop: "id", label: "ID", "min-width": "40" }
          }),
          _c("el-table-column", {
            attrs: {
              prop: "title",
              label: _vm.$t("gameSetting.title"),
              "min-width": "80"
            }
          }),
          _c("el-table-column", {
            attrs: {
              prop: "type",
              label: _vm.$t("gameSetting.type"),
              "min-width": "70"
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(scope) {
                  return [
                    _c("span", [
                      _vm._v(" " + _vm._s(_vm.getType(scope.row.type)))
                    ])
                  ]
                }
              }
            ])
          }),
          _c("el-table-column", {
            attrs: {
              prop: "mailContent",
              label: _vm.$t("gameSetting.mailContent"),
              "min-width": "200"
            }
          }),
          _c("el-table-column", {
            attrs: {
              prop: "mailTime",
              label: _vm.$t("gameSetting.mailTime"),
              "min-width": "100"
            }
          }),
          _c("el-table-column", {
            attrs: {
              prop: "uids",
              label: _vm.$t("gameSetting.uids"),
              "min-width": "120"
            }
          }),
          _c("el-table-column", {
            attrs: {
              prop: "type",
              label: _vm.$t("gameSetting.itemType"),
              "min-width": "100"
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(scope) {
                  return [
                    _c("span", [_vm._v(" " + _vm._s(scope.row.itemType))])
                  ]
                }
              }
            ])
          }),
          _c("el-table-column", {
            attrs: {
              prop: "type",
              label: _vm.$t("gameSetting.itemCount"),
              "min-width": "120"
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(scope) {
                  return [
                    _c("span", [_vm._v(" " + _vm._s(scope.row.itemCount || 0))])
                  ]
                }
              }
            ])
          }),
          _c("el-table-column", {
            attrs: {
              prop: "type",
              label: _vm.$t("gameSetting.timeRange"),
              "min-width": "80"
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(scope) {
                  return [
                    _c("span", [_vm._v(" " + _vm._s(scope.row.timeRange || 0))])
                  ]
                }
              }
            ])
          }),
          _c("el-table-column", {
            attrs: {
              prop: "time",
              label: _vm.$t("gameSetting.time"),
              "min-width": "100"
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(scope) {
                  return [
                    _c("span", [
                      _vm._v(_vm._s(_vm.formattedDate(scope.row.time)))
                    ])
                  ]
                }
              }
            ])
          })
        ],
        1
      ),
      _c("pagination", {
        directives: [
          {
            name: "show",
            rawName: "v-show",
            value: _vm.total > 0,
            expression: "total>0"
          }
        ],
        attrs: {
          total: _vm.total,
          page: _vm.listQuery.page,
          limit: _vm.listQuery.limit
        },
        on: {
          "update:page": function($event) {
            return _vm.$set(_vm.listQuery, "page", $event)
          },
          "update:limit": function($event) {
            return _vm.$set(_vm.listQuery, "limit", $event)
          },
          pagination: _vm.getList
        }
      }),
      _c(
        "el-dialog",
        {
          attrs: {
            title: _vm.$t("gameSetting.addMail"),
            visible: _vm.dialogEditorVisible,
            "modal-append-to-body": false,
            "before-close": _vm.onDialogClose
          },
          on: {
            "update:visible": function($event) {
              _vm.dialogEditorVisible = $event
            }
          }
        },
        [
          _c(
            "el-form",
            {
              ref: "formData",
              staticClass: "activity-form",
              attrs: {
                model: _vm.formData,
                rules: _vm.ruleData,
                "label-width": "120px"
              }
            },
            [
              _c(
                "el-form-item",
                {
                  attrs: {
                    prop: "emailTemplate",
                    label: _vm.$t("gameSetting.emailTemplate")
                  }
                },
                [
                  _c(
                    "el-select",
                    {
                      attrs: {
                        placeholder: _vm.$t("gameSetting.selectEmailTemplate"),
                        width: "220px"
                      },
                      on: {
                        change: function($event) {
                          return _vm.selectTrigger3()
                        }
                      },
                      model: {
                        value: _vm.formData.emailTemplate,
                        callback: function($$v) {
                          _vm.$set(_vm.formData, "emailTemplate", $$v)
                        },
                        expression: "formData.emailTemplate"
                      }
                    },
                    _vm._l(_vm.emailTemplates || [], function(item, key) {
                      return _c("el-option", {
                        key: key,
                        attrs: { label: _vm.$t(item.dec), value: item.value }
                      })
                    }),
                    1
                  )
                ],
                1
              ),
              _c(
                "el-form-item",
                {
                  attrs: { prop: "title", label: _vm.$t("gameSetting.title") }
                },
                [
                  _c("el-input", {
                    attrs: {
                      type: "text",
                      placeholder: _vm.$t("gameSetting.inputTitle")
                    },
                    model: {
                      value: _vm.formData.title,
                      callback: function($$v) {
                        _vm.$set(_vm.formData, "title", $$v)
                      },
                      expression: "formData.title"
                    }
                  })
                ],
                1
              ),
              _c(
                "el-form-item",
                {
                  attrs: {
                    prop: "mailContent",
                    label: _vm.$t("gameSetting.mailContent")
                  }
                },
                [
                  _c("el-input", {
                    attrs: {
                      type: "textarea",
                      placeholder: _vm.$t("gameSetting.inputMailContent")
                    },
                    model: {
                      value: _vm.formData.mailContent,
                      callback: function($$v) {
                        _vm.$set(_vm.formData, "mailContent", $$v)
                      },
                      expression: "formData.mailContent"
                    }
                  })
                ],
                1
              ),
              _c(
                "el-form-item",
                {
                  attrs: {
                    prop: "isDelaySend",
                    label: _vm.$t("gameSetting.isDelaySend")
                  }
                },
                [
                  _c("el-checkbox", {
                    on: { change: _vm.showRestartTime },
                    model: {
                      value: _vm.formData.isDelaySend,
                      callback: function($$v) {
                        _vm.$set(_vm.formData, "isDelaySend", $$v)
                      },
                      expression: "formData.isDelaySend"
                    }
                  })
                ],
                1
              ),
              _vm.isDelaySend
                ? _c(
                    "el-form-item",
                    {
                      staticClass: "postInfo-container-item",
                      attrs: {
                        "label-width": "120px",
                        label: _vm.$t("gameSetting.mailTime")
                      }
                    },
                    [
                      _c("el-date-picker", {
                        staticClass: "filter-item",
                        staticStyle: { width: "300px" },
                        attrs: {
                          type: "datetime",
                          align: "right",
                          "unlink-panels": "",
                          placeholder: _vm.$t("operation.selectDatetime"),
                          "picker-options": _vm.pickerOptions1,
                          "value-format": "yyyy-MM-dd HH:mm",
                          format: "yyyy-MM-dd HH:mm"
                        },
                        model: {
                          value: _vm.formData.mailTime,
                          callback: function($$v) {
                            _vm.$set(_vm.formData, "mailTime", $$v)
                          },
                          expression: "formData.mailTime"
                        }
                      })
                    ],
                    1
                  )
                : _vm._e(),
              _c(
                "el-form-item",
                { attrs: { prop: "type", label: _vm.$t("gameSetting.type") } },
                [
                  _c(
                    "el-select",
                    {
                      attrs: {
                        placeholder: _vm.$t("gameSetting.selectType"),
                        width: "220px"
                      },
                      on: {
                        change: function($event) {
                          return _vm.selectTrigger()
                        }
                      },
                      model: {
                        value: _vm.formData.type,
                        callback: function($$v) {
                          _vm.$set(_vm.formData, "type", $$v)
                        },
                        expression: "formData.type"
                      }
                    },
                    _vm._l(_vm.types || [], function(item, key) {
                      return _c("el-option", {
                        key: key,
                        attrs: { label: _vm.$t(item.dec), value: item.value }
                      })
                    }),
                    1
                  )
                ],
                1
              ),
              _vm.showData
                ? _c(
                    "div",
                    [
                      _vm.formData.type !== "1"
                        ? _c(
                            "div",
                            [
                              _c(
                                "el-form-item",
                                {
                                  attrs: {
                                    prop: "uids",
                                    label: _vm.$t("gameSetting.uids"),
                                    rules: {
                                      required: true,
                                      message: _vm.$t("gameSetting.notEmpty"),
                                      trigger: "blur"
                                    }
                                  }
                                },
                                [
                                  _c("el-input", {
                                    attrs: {
                                      type: "textarea",
                                      placeholder: _vm.$t(
                                        "gameSetting.inputMailUids"
                                      )
                                    },
                                    model: {
                                      value: _vm.formData.uids,
                                      callback: function($$v) {
                                        _vm.$set(_vm.formData, "uids", $$v)
                                      },
                                      expression: "formData.uids"
                                    }
                                  })
                                ],
                                1
                              )
                            ],
                            1
                          )
                        : _vm._e(),
                      _vm._l(_vm.select, function(item, index) {
                        return _c(
                          "div",
                          { key: index },
                          [
                            _c(
                              "el-form-item",
                              {
                                attrs: {
                                  prop: "itemType",
                                  label:
                                    _vm.$t("gameSetting.itemType") + (index + 1)
                                }
                              },
                              [
                                _c(
                                  "el-select",
                                  {
                                    attrs: {
                                      placeholder: _vm.$t(
                                        "gameSetting.selectItemType"
                                      )
                                    },
                                    on: {
                                      change: function($event) {
                                        return _vm.selectTrigger2(index)
                                      }
                                    },
                                    model: {
                                      value: _vm.select[index].value,
                                      callback: function($$v) {
                                        _vm.$set(
                                          _vm.select[index],
                                          "value",
                                          $$v
                                        )
                                      },
                                      expression: "select[index].value"
                                    }
                                  },
                                  _vm._l(_vm.itemTypes || [], function(
                                    item2,
                                    key
                                  ) {
                                    return _c("el-option", {
                                      key: key,
                                      attrs: {
                                        label: _vm.$t(item2.dec),
                                        value: item2.value
                                      }
                                    })
                                  }),
                                  1
                                ),
                                _c(
                                  "el-button",
                                  {
                                    attrs: { type: "danger" },
                                    on: {
                                      click: function($event) {
                                        return _vm.deleteSelect(index)
                                      }
                                    }
                                  },
                                  [_c("i", { staticClass: "el-icon-minus" })]
                                )
                              ],
                              1
                            ),
                            _vm.formData.items[index] && item.value !== 3
                              ? _c(
                                  "el-form-item",
                                  {
                                    attrs: {
                                      prop: "itemCount",
                                      label:
                                        _vm.$t("gameSetting.itemCount") +
                                        (index + 1)
                                    }
                                  },
                                  [
                                    _c("el-input", {
                                      attrs: {
                                        type: "text",
                                        placeholder: _vm.$t(
                                          "gameSetting.inputItemCount"
                                        )
                                      },
                                      on: { input: _vm.forceUpdate },
                                      model: {
                                        value:
                                          _vm.formData.items[index].itemCount,
                                        callback: function($$v) {
                                          _vm.$set(
                                            _vm.formData.items[index],
                                            "itemCount",
                                            $$v
                                          )
                                        },
                                        expression:
                                          "formData.items[index].itemCount"
                                      }
                                    })
                                  ],
                                  1
                                )
                              : _vm._e(),
                            _vm.formData.items[index] && item.value === 3
                              ? _c(
                                  "el-form-item",
                                  {
                                    attrs: {
                                      prop: "itemCount",
                                      label:
                                        _vm.$t("gameSetting.itemId") +
                                        (index + 1)
                                    }
                                  },
                                  [
                                    _c("el-input", {
                                      attrs: {
                                        type: "text",
                                        placeholder: _vm.$t(
                                          "gameSetting.inputItemType"
                                        )
                                      },
                                      on: { input: _vm.forceUpdate },
                                      model: {
                                        value:
                                          _vm.formData.items[index].itemType,
                                        callback: function($$v) {
                                          _vm.$set(
                                            _vm.formData.items[index],
                                            "itemType",
                                            $$v
                                          )
                                        },
                                        expression:
                                          "formData.items[index].itemType"
                                      }
                                    })
                                  ],
                                  1
                                )
                              : _vm._e(),
                            _vm.formData.items[index] && item.value === 3
                              ? _c(
                                  "el-form-item",
                                  {
                                    attrs: {
                                      prop: "itemCount",
                                      label:
                                        _vm.$t("gameSetting.itemCount") +
                                        (index + 1)
                                    }
                                  },
                                  [
                                    _c("el-input", {
                                      attrs: {
                                        type: "text",
                                        placeholder: _vm.$t(
                                          "gameSetting.inputItemCount"
                                        )
                                      },
                                      on: { input: _vm.forceUpdate },
                                      model: {
                                        value:
                                          _vm.formData.items[index].itemCount,
                                        callback: function($$v) {
                                          _vm.$set(
                                            _vm.formData.items[index],
                                            "itemCount",
                                            $$v
                                          )
                                        },
                                        expression:
                                          "formData.items[index].itemCount"
                                      }
                                    })
                                  ],
                                  1
                                )
                              : _vm._e(),
                            _vm.formData.items[index] && item.value === 3
                              ? _c(
                                  "el-form-item",
                                  {
                                    attrs: {
                                      prop: "itemCount",
                                      label:
                                        _vm.$t("gameSetting.timeRange") +
                                        (index + 1)
                                    }
                                  },
                                  [
                                    _c("el-input", {
                                      attrs: {
                                        type: "text",
                                        placeholder: _vm.$t(
                                          "gameSetting.inputItemTimeRange"
                                        )
                                      },
                                      on: { input: _vm.forceUpdate },
                                      model: {
                                        value:
                                          _vm.formData.items[index].timeRange,
                                        callback: function($$v) {
                                          _vm.$set(
                                            _vm.formData.items[index],
                                            "timeRange",
                                            $$v
                                          )
                                        },
                                        expression:
                                          "formData.items[index].timeRange"
                                      }
                                    })
                                  ],
                                  1
                                )
                              : _vm._e()
                          ],
                          1
                        )
                      }),
                      _c(
                        "el-form-item",
                        [
                          _c(
                            "el-button",
                            {
                              attrs: { type: "primary" },
                              on: { click: _vm.addSelect }
                            },
                            [_c("i", { staticClass: "el-icon-plus" })]
                          )
                        ],
                        1
                      )
                    ],
                    2
                  )
                : _vm._e(),
              _c("el-form-item", [
                _c(
                  "button",
                  {
                    staticClass: "el-button el-button--primary",
                    attrs: { type: "submit" },
                    on: {
                      click: function($event) {
                        $event.preventDefault()
                        return _vm.saveOrUpdate("formData")
                      }
                    }
                  },
                  [
                    _vm._v(
                      "\n          " +
                        _vm._s(_vm.$t("gameSetting.addMail")) +
                        "\n        "
                    )
                  ]
                )
              ])
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }